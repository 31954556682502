import './bootstrap';
import '../css/app.css';

import { createRoot } from 'react-dom/client';
import { createInertiaApp } from '@inertiajs/react';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import Bugsnag from '@bugsnag/js';
import React from 'react';

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

const ErrorView = () => (
  <div>
    <h1>Ops. Something went wrong</h1>
    <a href="/">Go back home</a>
  </div>
);

createInertiaApp({
  resolve: (name) => resolvePageComponent(`./Pages/${name}.jsx`, import.meta.glob('./Pages/**/*.jsx')),
  setup({ el, App, props }) {
    const root = createRoot(el);

    props.titleCallback = (title) =>
      title ? `${title} - ${props.initialPage.props.app.name}` : props.initialPage.props.app.name;

    if (window.hj && props.initialPage?.props?.auth?.user?.user?.id) {
      window.hj('identify', props.initialPage?.props?.auth?.user?.user?.id);
    }

    root.render(
      <ErrorBoundary FallbackComponent={ErrorView}>
        <App {...props} />
      </ErrorBoundary>
    );
  },
  progress: {
    color: '#4B5563',
  },
});
